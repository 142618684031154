import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import UOBLogo from "../images/core/uob-logo.svg"

const Hero: React.FC = () => {
  return ( <>
    <div className="text-center z-10">
    <StaticImage 
    src="../images/core/css_bbq.jpg" 
    alt="CSS Members coding"
    className="absolute -mt-20 h-screen right-0"
    imgClassName="w-screen h-screen t-0 w-0 mix-blend-multiply"
    imgStyle={{'maskImage': "radial-gradient(at 120% 0%, black 0%, transparent 70%)",
    'WebkitMaskImage': "radial-gradient(at 120% 0%, black 0%, transparent 70%)"}}
    loading="eager"
    placeholder="none"
    style={{'position': 'absolute'}}
  />
      <div className="relative text-center font-display uppercase pt-12 text-5xl leading-normal">
        <h1>University of Bristol</h1>
        <h1>Computer Science Society</h1>
      </div>
      <div>
        <p className="relative pt-8">In association with</p>
        <div className="flex gap-4 justify-center items-center pt-4">
        <img src={UOBLogo} 
          alt="UoB logo" 
          style={{'fill': 'white'}}
          className="relative h-20"
        />
        </div>
      </div>
    </div>
  </>)
}

export default Hero