import { ArrowRightIcon } from "@heroicons/react/solid"
import * as React from "react"

type Props = {
  title: string,
  date: string,
  className: string,
  location: string

}

const EventCard = ({ title, className, date, location}: Props) => {
  return <div className={`p-4 w-80 rounded-md flex flex-col ${className}`}>
    <h4 className="text-xl font-bold">{title}</h4>
    <p><span className="font-bold">Date:</span> {date}</p>
    <p><span className="font-bold">Location:</span> {location}</p>
    <div className="flex-1"></div>
    <p className="pt-4 justify-self-end text-sm font-bold">Event details <ArrowRightIcon className="h-3 w-3 inline"/> </p>
  </div>
}

export default EventCard