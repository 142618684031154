import * as React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout/layout"
import { ArrowRightIcon } from "@heroicons/react/outline"
import EventCard from "../components/events/event-card"
import SEO from "../components/layout/seo"
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  const allEvents = data.allMarkdownRemark.edges
  return <Layout>
    <SEO title="Home"/>
    {/* Seperate hero component for layout abuse */}
    <Hero />

    {/* Event carosel */}
    <div className="relative py-12 z-10">
      <h2 className="font-display text-4xl">Upcoming Events</h2>
      <p className="pt-4 inline-block"> View all events <ArrowRightIcon className="h-5 w-5 inline"/></p>
      <div className="py-4 flex flex-nowrap overflow-x-hidden gap-4">
        {allEvents.map(event => <EventCard 
          title={event.node.frontmatter.title}
          className="flex-none bg-neutral-50/25 fade"
          date={event.node.frontmatter.date}
          location={event.node.frontmatter.location}
          key={event.node.fields.slug}
        />)}
      </div>
    </div>

    {/* Sponsor layout */}
  </Layout>
}

export const query = graphql`
query GetNextEvents {
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {fields: {collection: {eq: "events"}}, frontmatter: {published: {eq: true}}}
    limit: 5
  ) {
    edges {
      node {
        frontmatter {
          title
          date(fromNow: true)
          banner
          location
        }
        fields {
          slug
        }
      }
    }
  }
}
`

export default IndexPage
